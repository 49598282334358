<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Menubar :model="items" class="p-shadow-6">
        <template #start>
          <img
            alt="logo"
            src="../assets/img/accyon.png"
            height="40"
            class="p-mr-2"
          />
        </template>
      </Menubar>
    </div>
    <div class="p-col-8 p-offset-2" v-if="isLoggedIn">
      <Card>
        <template #content>
          <router-view></router-view>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import firebase from "../Firebase";

export default {
  data() {
    return {
      isLoggedIn: true,
      items: [
        {
          label: "Vacantes",
          icon: "pi pi-fw pi-briefcase",
          to: { name: "admin-jobs" },
        },
        {
          label: "Imagenes",
          icon: "pi pi-fw pi-images",
          to: { name: "admin-images" },
        },
      ],
    };
  },
  mounted() {
    // runs after firebase is initialized
    const context = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        context.isLoggedIn = true; // if we have a user
        context.items.push({
          label: "Salir",
          icon: "pi pi-fw pi-sign-out",
          command: () => {
            firebase
              .auth()
              .signOut()
              .catch((error) => {
                context.$toast.add({
                  severity: "error",
                  summary: "Autenticación falló",
                  detail: `Error [${error.code}]: ${error.message}`,
                  life: 6000,
                });
              });
          },
        });
      } else {
        context.isLoggedIn = false; // if we do not
      }
    });
  },
  beforeMount() {
    const context = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (!user) {
        // not logged in
        context.$router.push({ name: "login" });
      }
    });
  },
};
</script>

<style scoped>
.p-card {
  margin-top: 5vh;
}
</style>
